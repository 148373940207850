<script setup>
import { WdsButtonGhost } from '@wds/components';
import { useCurrencyStore } from '~/stores/currency';
import { GA_ACCOUNTS } from '~/config/analytics';
import { commonValues } from '~/config/common.js';

const runtimeConfig = useRuntimeConfig();
const { $tracking } = useNuxtApp();
const {
  t,
  locale,
} = useI18n();
const { useUrl } = useUrls();
const route = useRoute();
const req = useRequestURL();
const currencyStore = useCurrencyStore();
const testingStore = useTestingStore();
const languageStore = useLanguageStore();
const { translateUrl } = useApiRedirection();
const envData = runtimeConfig?.public;
const isGoogleTagManagerEnabled = testingStore?.hasEnabledFeature('web_googleTagManager');

const redirectUrlData = await translateUrl(req?.origin, route?.path, route?.query);

if (redirectUrlData && redirectUrlData?.baseUrl && redirectUrlData?.path && redirectUrlData?.path !== route?.path) {
  console.error(`### Translated to ${redirectUrlData?.baseUrl}${redirectUrlData?.path}`);
  clearError();
  await navigateTo(redirectUrlData?.path, {
    redirectCode: 301,
    external: redirectUrlData?.domainRedirect,
  });
}

const COOKIESPOLICYURL = useUrl.getCookiePolicyUrl();
const SECURITYPRIVACYURL = useUrl.getSecurityPrivacyUrl();

const actualLanguage = locale?.value;

// @TODO: For some weird reason, the trustArc banner is being rendered twice on DOM, but script is only called once
const trustArcScript = [
  {
    async: true,
    crossorigin: 'anonymous',
    fetchpriority: 'high',
    src:
      'https://consent.trustarc.com/notice?domain=hostelworld.com'
      + '&c=teconsent'
      + '&gtm=1'
      + '&js=nj'
      + '&noticeType=bb'
      + `&language=${actualLanguage}`
      + '&language=true'
      + '&text=true'
      + `&cookieLink=${encodeURIComponent(`${COOKIESPOLICYURL}`)}&privacypolicylink=${encodeURIComponent(
        `${SECURITYPRIVACYURL}`,
      )}`,
  },
];

const googleHpa = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtag',
      defer: true,
      crossorigin: 'anonymous',
      src: `https://www.googletagmanager.com/gtag/js?id=${GA_ACCOUNTS[0].ID}`,
    },
  ]
  : [];

const gtm = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtm',
      defer: true,
      crossorigin: 'anonymous',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${envData.GTM_ID}');`,
    },
  ]
  : [];

const headerScripts = [...trustArcScript, ...googleHpa, ...gtm];

const lightBulbClicks = ref(0);
const nyanCat = ref(false);
let eye = null;

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  script: headerScripts,
}));

const seoMetaData = {
  title: `${commonValues?.HOSTELWORLD} - 404 - ${t('t_ERROR404TITLE')}`,
  ogTitle: `${commonValues?.HOSTELWORLD} - 404 - ${t('t_ERROR404TITLE')}`,
  description: t('t_ERROR404TEXT'),
  ogDescription: t('t_ERROR404TEXT'),
  canonicalUrl: commonValues?.HOSTELWORLDURL,
  ogImage: commonValues?.OGIMAGE,
  keywords: '',
  copy: '',
  heading: '',
  h1: '',
  h2: '',
};

useSeoMeta({
  title: () => seoMetaData?.title,
  ogTitle: () => seoMetaData?.ogTitle,
  description: () => seoMetaData?.description,
  ogDescription: () => seoMetaData?.ogDescription,
  ogUrl: () => seoMetaData?.canonicalUrl,
  ogImage: () => seoMetaData?.ogImage,
  ogImageAlt: () => seoMetaData?.ogDescription,
  ogType: () => 'website',
  keywords: () => seoMetaData?.keywords,
  robots: () => 'noindex, nofollow',
  twitterCard: () => 'summary_large_image',
  twitterTitle: () => seoMetaData?.ogTitle,
  twitterDescription: () => seoMetaData?.ogDescription,
  twitterImage: () => seoMetaData?.ogImage,
  twitterImageAlt: () => seoMetaData?.ogDescription,
});

// ### TRACKING
const trackingInfo = {
  gtm: { gtmPageName: 'error404Page' },
  segment: {
    event_name: '404',
    page_type: '404',
  },
};
$tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);

const sendTrackingEvent = function () {
  const trackingInfo = {
    gtm: { parameter1: 'easterEgg' },
    segment: {
      event_name: 'Error 404 Page Event',
      action: 'Easter Egg found',
    },
  };
  $tracking?.onEasterEggFound(trackingInfo.gtm, trackingInfo.segment);
};

// ### METHODS
const setupEyes = function () {
  eye = document?.querySelectorAll('.eye');
};

const mouseMove = function (event) {
  if (eye) {
    eye?.forEach((elem) => {
      const x = elem.getBoundingClientRect().left + elem.clientWidth / 2;
      const y = elem.getBoundingClientRect().top + elem.clientHeight / 2;
      const radian = Math.atan2(event.pageX - x, event.pageY - y);
      const rotate = radian * (180 / Math.PI) * -1 + 270;
      elem.style.transform = `rotate(${rotate}deg)`;
    });
  }
};

const redirectPage = function () {
  setTimeout(() => {
    clearError({ redirect: '/' });
  }, 100);
};

const handleLightBulbClick = function () {
  lightBulbClicks.value++;

  if (lightBulbClicks?.value === 9) {
    nyanCat.value = !nyanCat?.value;
    lightBulbClicks.value = 0;
    sendTrackingEvent();
  }
};

onMounted(() => {
  setupEyes();
});

onUnmounted(() => {
  clearError();
});
</script>

<template>
  <div
    class="container"
    @mousemove="mouseMove"
  >
    <div class="wrapper">
      <div class="inner-wrapper">
        <CommonHwNavBar />
      </div>
    </div>

    <CommonHwLanguagePicker v-show="languageStore?.isLanguagePickerOpen" />

    <ClientOnly>
      <LazyCommonHwCurrencyPicker v-if="currencyStore?.isCurrencyPickerOpen" />
    </ClientOnly>

    <ErrorsNyanCat :enabled="nyanCat" />

    <main class="error-404-page">
      <div class="wrapper">
        <div class="inner-wrapper">
          <div class="content">
            <div class="error-img">
              <img
                src="@/public/404-cat.svg"
                :alt="$t('t_ERROR404TITLE')"
                :title="$t('t_ERROR404TITLE')"
              />
              <div
                class="lightbulb"
                @click="handleLightBulbClick"
                @keyup.enter="handleLightBulbClick"
              />
              <div
                id="left-eye"
                class="eye"
              />
              <div
                id="right-eye"
                class="eye"
              />
            </div>

            <div class="error-text">
              <h1>{{ $t('t_ERROR404TITLE') }}</h1>
              <h2>{{ $t('t_ERROR404TEXT') }}</h2>

              <WdsButtonGhost
                type="button"
                class="error-button"
                :reversed="true"
                :text="$t('t_ERROR404BUTTON')"
                icon-start="hostel"
                rel="noopener"
                @click="redirectPage"
                @keyup.enter="redirectPage"
              />
            </div>
          </div>
        </div>
      </div>
    </main>

    <CommonHwFooter />

    <ClientOnly>
      <LazyCommonHwCookieBar />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
nav {
  margin-top: $wds-spacing-s;
  margin-bottom: $wds-spacing-m;
}

.error-404-page {
  background-color: $wds-color-ink-darker;
  padding: 0 0 wds-rem(70px);
  position: relative;
  width: 100%;
  clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);
  z-index: 10;

  .inner-wrapper {
    margin: 0;
  }

  .content {
    padding: $wds-spacing-m;
    display: grid;
    grid-template: auto / 1fr;
    gap: 0;
    grid-auto-flow: row;
    grid-template-areas:
      'error-img'
      'error-text';

    @include tablet-large {
      padding: $wds-spacing-m;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'error-text error-img';
      max-width: wds-rem(960px);
      margin: 0 auto;
    }

    .error-img {
      grid-area: error-img;
      width: wds-rem(290px);
      margin: wds-rem(-70px) auto $wds-spacing-m;
      position: relative;

      @include tablet-large {
        width: wds-rem(450px);
        margin: wds-rem(-100px) auto $wds-spacing-m;
      }

      #left-eye,
      #right-eye,
      .lightbulb {
        display: none;
      }

      @include desktop {
        #left-eye,
        #right-eye {
          position: absolute;
          display: inline-block;
          border-radius: 50%;
          height: wds-rem(22px);
          width: wds-rem(22px);
          background: $wds-color-sunset-light;
        }

        #right-eye {
          bottom: wds-rem(112px);
          right: wds-rem(102px);
        }

        #left-eye {
          bottom: wds-rem(88px);
          right: wds-rem(125px);
        }

        #right-eye::after {
          position: absolute;
          bottom: wds-rem(6px);
          right: wds-rem(9px);
          width: wds-rem(13px);
          height: wds-rem(15px);
          background: $wds-color-ink-darker;
          border-radius: 50%;
          content: ' ';
        }

        #left-eye::after {
          position: absolute;
          bottom: wds-rem(6px);
          right: wds-rem(9px);
          width: wds-rem(13px);
          height: wds-rem(15px);
          background: $wds-color-ink-darker;
          border-radius: 50%;
          content: ' ';
        }

        .lightbulb {
          position: absolute;
          top: wds-rem(192px);
          left: wds-rem(245px);
          background: transparent;
          width: wds-rem(31px);
          height: wds-rem(17px);
          display: inline-block;
        }
      }
    }

    .error-text {
      grid-area: error-text;

      h1 {
        margin-bottom: $wds-spacing-s;
        color: $wds-color-sunset-light;

        @include title-2-bld;
      }

      h2 {
        margin-bottom: $wds-spacing-s;
        color: $wds-color-white;

        @include title-5-bld;
      }

      @include tablet-large {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
          @include title-1-bld;
        }
      }
    }

    .error-button {
      margin-top: $wds-spacing-xl;
      width: 100%;
    }
  }
}

footer {
  margin-top: wds-rem(-70px);

  @include tablet {
    margin-top: wds-rem(-80px);
  }
}
</style>
