import { commonValues } from '~/config/common.js';
import { checkIfFile } from '~/lib/urls.js';

export function useApiRedirection() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const { useUrl } = useUrls();
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;
  const testingStore = useTestingStore();

  const isRedirectWorldwideEnabled = testingStore.hasEnabledFeature('web_redirectWorldwide');
  const isRedirectContinentEnabled = testingStore.hasEnabledFeature('web_redirectContinent');
  const isRedirectCountryEnabled = testingStore.hasEnabledFeature('web_redirectCountry');
  const isRedirectCityEnabled = testingStore.hasEnabledFeature('web_redirectCity');
  const isRedirectPropertyEnabled = testingStore.hasEnabledFeature('web_redirectProperty');
  const isRedirectDistrictEnabled = testingStore.hasEnabledFeature('web_redirectDistrict');
  const isRedirectAreaEnabled = testingStore.hasEnabledFeature('web_redirectArea');
  const isRedirectRegionEnabled = testingStore.hasEnabledFeature('web_redirectRegion');

  const defaultQuery = {};

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getActualInputBaseUrl = (baseUrl) => {
    let url = baseUrl || useUrl?.getPwaLangBaseUrl() || useUrl?.getStaticBaseURL() || commonValues?.HOSTELWORLDURL;

    // Hack to work in dev, since redirection api doesn't have a dev env
    if (envData.RELEASE_ENV === 'dev') {
      url = commonValues?.HOSTELWORLDURL;
    }

    return url?.replace(/\/$/, '');
  };

  const getActualPathUrl = (pathUrl) => {
    const path = pathUrl?.replace(/^\/|\/$/g, '');
    const pathParts = path?.split('/');

    if (pathParts && pathParts?.length > 0 && pathParts[0]?.length === 2) {
      pathParts?.shift();
    }

    return pathParts?.join('/')?.replace(/^\/|\/$/g, '');
  };

  const getLocalePathFromBaseUrl = (baseUrl) => {
    let actualLocale = null;
    const langEnvVars = {
      french: 'fr',
      german: 'de',
      spanish: 'es',
      italian: 'it',
      portuguese: 'pt',
      brazilian: 'br',
      swedish: 'sv',
      polish: 'pl',
      finnish: 'fi',
      danish: 'da',
      dutch: 'nl',
      norwegian: 'no',
      czech: 'cs',
      russian: 'ru',
      turkish: 'tr',
      chinese: 'zh',
      japanese: 'ja',
      korean: 'ko',
    };

    const pathUrlRegex = 'www.(\\w+).hostelworld';
    const baseUrlMatch = baseUrl?.match(new RegExp(pathUrlRegex, 'gi'));

    // We are on the subdomain language then we should redirect the user for the proper language subfolder
    if (baseUrlMatch && baseUrlMatch.length > 0 && baseUrlMatch[0]) {
      const baseUrlDomainLanguage = baseUrlMatch[0].replace('www.', '').replace('.hostelworld', '');
      if (baseUrlDomainLanguage in langEnvVars) {
        actualLocale = langEnvVars[baseUrlDomainLanguage];
      }
    }

    return actualLocale;
  };

  const processResults = (result, baseUrl, query, pathUrl) => {
    let domainRedirectUrl = '/';
    const queryParams = new URLSearchParams(query);
    const queryParamsString = queryParams && queryParams?.size > 0 ? `?${queryParams.toString()}` : '';

    let resultPath = null;

    if (
      !result
      || (result && (result?.success === false || result?.data?.path === 'st/404' || result?.data?.path === 'st/500'))
    ) {
      console.error(`### Invalid URL translation from ${baseUrl}: ${result?.message} > ${pathUrl}`);
      return null;
    }

    const responsePath = result?.data?.path?.replace(/\/$/, '');
    const pathObj = responsePath.split('/');

    const localePath = getLocalePathFromBaseUrl(baseUrl);
    if (localePath) {
      domainRedirectUrl = `${envData.BASE_URL_PROTOCOL}${envData.BASE_URL_DIVIDER}${envData.DEFAULT_BASE_URL}${
        envData[`BASE_URL_${localePath.toUpperCase()}`]
      }/`;
    }

    resultPath = `/${responsePath}/`;

    // @TODO: This is a temporary solution to properly redirect from "old" redirectionAPI to this new one and the migration
    // We will need to refactored RedirectionAPI afterwards to remove the /st/ from the response and this needs to be altered also
    const pathLength = pathObj.length;

    // This is taken into account that the response from RedirectionAPI brings the /st/ = pathObj[0]
    if (pathObj && pathLength > 1) {
      // Property type / Worldwide page
      if (pathLength === 2 && isRedirectWorldwideEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/`;

        // Continent Page
      } else if (pathLength === 3 && isRedirectContinentEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/`;

        // Country Page
      } else if (pathLength === 4 && isRedirectCountryEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/${pathObj[3]}/`;

        // Region Page
      } else if (pathLength === 6 && pathObj[4] === 'r' && isRedirectRegionEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/${pathObj[3]}/${pathObj[4]}/${pathObj[5]}/`;

        // Area Page
      } else if (pathLength === 6 && pathObj[4] === 'a' && isRedirectAreaEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/${pathObj[3]}/${pathObj[4]}/${pathObj[5]}/`;

        // City Page
      } else if (pathLength === 5 && isRedirectCityEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/${pathObj[3]}/${pathObj[4]}/`;

        // Districts Page
      } else if (pathLength === 7 && pathObj[5] === 'd' && isRedirectDistrictEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/${pathObj[3]}/${pathObj[4]}/${pathObj[5]}/${pathObj[6]}/`;

        // Property Page
      } else if (pathLength === 5 && pathObj[2] === 'p' && isRedirectPropertyEnabled) {
        resultPath = `${domainRedirectUrl}${pathObj[1]}/${pathObj[2]}/${pathObj[3]}/${pathObj[4]}/`;
      }
    }

    return {
      baseUrl: result?.data?.baseUrl,
      path: `${resultPath}${queryParamsString}`,
      domainRedirect: !!localePath,
    };
  };

  const shouldTranslateUrl = (pathUrl) => {
    let result = false;
    if (pathUrl && pathUrl !== '404' && pathUrl !== '500') {
      // If it isn't a file and the URL ends with a slash (added always on the redirect middleware)
      if (!checkIfFile(pathUrl)) {
        result = true;
      }
    }
    return result;
  };

  const translateUrl = async (baseUrl, pathUrl, queryUrl) => {
    let result = ref(null);

    const inputBaseUrl = getActualInputBaseUrl(baseUrl);

    pathUrl = getActualPathUrl(pathUrl);

    if (shouldTranslateUrl(pathUrl)) {
      const internalQuery = {
        baseUrl: inputBaseUrl,
        path: pathUrl,
        ...defaultQuery,
      };

      const callKey = `url-translator${locale?.value}${pathUrl}${queryUrl}`;
      const endpoint = 'url-translator/';

      result = ref(await $api.redirectionapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

      result = processResults(result?.value, inputBaseUrl, queryUrl, pathUrl);
    }

    return result || null;
  };

  return { translateUrl };
}
